export default class WordType {
	id;
	isSelected;
	word;

	constructor(word, isSelected) {
		this.id = 0;
		this.isSelected = isSelected;
		this.word = word;
	}
}
