import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Bingo from './Bingo';
import NotifyMsg from './NotifyMsg';

function App(props) {

  return (
    <React.Fragment>
      <header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-green" id="top">
          <a className="navbar-brand" href="#top"><img srcSet="We_Icon_32.png 1x, We_Icon_64.png 2x, We_Icon_96.png 3x" src="We_Icon_64.png" alt="Bingo!" /></a>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#play">Spielen</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#imprint">Impressum</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    
      <main role="main" className="flex-shrink-0">
        <div className="container-fluid px-0">
          <NotifyMsg notify={props.notify}></NotifyMsg>
          <div className="row no-gutters">
            <div className="col-0 col-md-2 col-xl-3"></div>
            <div className="col-12 col-md-8 col-xl-6">
              <Bingo id="play"></Bingo>
            </div>
            <div className="col-0 col-md-2 col-xl-3"></div>
          </div>
        </div>
      </main>
      
      <footer className="footer mt-auto py-3 bg-light">
        <div className="container">
          <span id="imprint" className="text-muted">Diese Seite wird bereitgestellt durch: Frank Stützer - <a href="https://www.seito.de/bingo" rel="noopener noreferrer" target="_blank">www.seito.de</a></span>
        </div>
      </footer>
    </React.Fragment>

  );
}

export default App;
