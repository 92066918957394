import React, { Component } from 'react';

import Cell from './Cell';
import { Row, Container } from 'react-bootstrap';

class Table extends Component {

	handleOnSelect = (idx) => {
		this.props.onSelect(idx);
	}

	render() {
		let rows = [];
		if( this.props.words !== undefined && this.props.words !== null) {
			let cells = this.props.words.map((word) => {
				return <Cell key={word.id} listId={word.id} word={word} onSelect={this.handleOnSelect}></Cell>;
			});

			let r = [];
			for(let i=0; i<cells.length; i++) {
				if( i > 0 && (i % 3 === 0)) {
					rows.push(
						<Row key={i*200} className="border-top-bottom my-border-left my-border-right">{r}</Row>
						);
						r = [];
				}
				r.push(cells[i]);
				if( i === cells.length-1 ) {
					rows.push(
						<Row key={(i+1)*200} className="border-top-bottom my-border-bottom my-border-left my-border-right">{r}</Row>
					);
				}
			}
		}
		return (
			<Container fluid className="mt-3 mb-3">
				{rows}
			</Container>
		);
	}
}

export default Table;