export default class WordTypeHandle {
	words;
	lastId;

	constructor() {
		this.words = [];
		this.lastId = 0;
	}

	push = (word) => {
		this.lastId++;
		word.id = this.lastId;
		this.words.push(word);
	}

	storeSet = (words) => {
		this.clear();
		let ws = words;
		if( words["words"] !== undefined ) {
			ws = words.words;
		}
		ws.forEach(w => {
			this.push(w);
		});
	}

	getIndex = (id) => {
		return this.words.findIndex(i => i.id === id);
	}

	getEntry = (id) => {
		const idx = this.getIndex(id);
		if( idx === -1 )
			return null;
		return this.words[idx];
	}

	getAll = () => {
		return this.words;
	}

	remove = (id) => {
		const idx = this.words.findIndex(i => i.id === id);
		if( idx > -1 ) {
			this.words.splice(idx, 1);
		}
	}

	clear = () => {
		this.words = [];
		this.lastId = 0;
		localStorage.removeItem("words");
	}

	changeWord = (newWord, id) => {
		const w = this.getEntry(id);
		if( w !== null ) {
			w.word = newWord;
		}
	}

	changeSelected = (id) => {
		const w = this.getEntry(id);
		if( w !== null ) {
			w.isSelected = !w.isSelected;
		}
	}

	resetSelected = () => {
		this.words.forEach(w => w.isSelected = false);
	}

	shuffle = () => {
		let nw = [...this.words];
		for(let i=0; i<nw.length; i++) {
			let idx = Math.floor(Math.random() * i);
			let v = nw[i];
			nw[i] = nw[idx];
			nw[idx] = v;
		}
		this.words = [...nw];
	}
}