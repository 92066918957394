import { Subject } from "rxjs";

export default class ServiceWorkerNotify {

	state = new Subject();

	onUpdate = (registration) => {
		this.state.next({worker: registration, type: "UPDATE"});
	}

	onSuccess = (registration) => {
		this.state.next({worker: registration, type: "SUCCESS"});
	}
}
