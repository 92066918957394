import React, { Component } from 'react';
import { Container, Tab, Row, Col, Nav } from 'react-bootstrap';
import Table from './Table';
import Settings from './Settings';
import Intro from './Intro';
import { SettingsIcon } from '@primer/octicons-react';
import SavedPresets from './Saved-Presets';
import PresetHandler from './Preset-Handler';
import FinishedAlert from './FinishedAlert';
import Words from './Words';

class Bingo extends Component {

	presetHandle;
	currentPreset;

	constructor(props) {
		super(props);

		this.state = {preset: null, presets: [], notify: false, shownIntro: true, isFinished: false};
	}

	componentDidMount() {
		this.presetHandle = new PresetHandler();
		// shuffle entries
		this.presetHandle.shuffle();
		this.currentPreset = this.presetHandle.getFirst();
		this.setState({presets: this.presetHandle.getAll(true), preset: this.currentPreset});
	}

	updatePreset = () => {
		this.setState({preset: this.currentPreset}, () => {
			this.presetHandle.saveToLocalStorage();
		});
	}

	handleAddInput = (words) => {
		this.currentPreset.createWord();
		this.updatePreset();
	}

	handleChangeWord = (newWord) => {
		this.currentPreset.changeWord(newWord.word, newWord.id);
		this.updatePreset();
	}

	handleOnSelect = (id) => {
		if( this.state.isFinished === false ) {
			this.currentPreset.toggleSelected(id);
			this.setState({preset: this.currentPreset}, () => {
				this.checkIfFinished(id);
			});
		} else {
			this.checkIfFinished(id);
		}
	}

	handleOnWordDelete = (id) => {
		this.currentPreset.deleteWord(id);
		this.updatePreset();
	}

	handleOnDeleteStorage = (deleteAll) => {
		this.presetHandle.clear();
		this.setState({preset: null, presets: this.presetHandle.getAll()});
	}

	handlePresetOnSelected = (id) => {
		const p = this.presetHandle.getById(+id);
		if( p !== null ) {
			this.currentPreset = p;
			this.updatePreset();
		}
	}

	handlePresetOnSave = (id, name) => {
		if( id > -1 ) {
			this.currentPreset.setName(name);
			this.setState({preset: this.currentPreset, presets: this.presetHandle.getAll()}, () => {
				this.presetHandle.saveToLocalStorage();
			});
		}
	}

	handlePresetOnDelete = (id) => {
		const newId = this.presetHandle.deleteById(id);
		this.currentPreset = this.presetHandle.getById(newId);
		this.setState({preset: this.currentPreset, presets: this.presetHandle.getAll()});
	}

	handlePresetOnCreate = () => {
		const id = this.presetHandle.createNew();
		this.currentPreset = this.presetHandle.getById(id);
		this.setState({preset: this.currentPreset, presets: this.presetHandle.getAll()});
	}

	checkIfFinished = (id) => {
		// start at the clicked id
		// check vertical && horizontal 
		const idx = this.currentPreset.getIndexById(id);
		const ws = this.currentPreset.getWords();
		const wLen = ws.length;
		const rows = Math.floor(wLen / 3);

		const pos = {col: idx % 3, row: Math.floor(idx/3)};
		const f = {h:0, v:0};

		let isFinished = false;
		let startC = idx-pos.col;
		let endC = startC + 3
		// horizontal
		for(let i=startC; i<endC; i++) {
			if(ws[i].isSelected===true)
				f.h++;
		}
		// vertical
		for(let i=idx-(pos.row*3); i<wLen; i+=3) {
			if(ws[i].isSelected===true)
				f.v++;
		}
		if( (f.v === rows) || (f.h === 3) ) {
			isFinished = true;
		}
		this.setState({isFinished: isFinished});
	}

	handleRestart = () => {
		this.currentPreset.resetSelected();
		this.setState({preset: this.currentPreset, isFinished: false});
	}

	handleIntroOnConfirm = () => {
		this.setState({shownIntro: false});
	}

	render() {

		const words = this.state.preset === null ? null : this.state.preset.getWords();

		return (
			<React.Fragment>
			<Intro show={this.state.shownIntro} onConfirm={this.handleIntroOnConfirm}></Intro>
			<Container className="App px-0" fluid>
				<FinishedAlert onRestart={this.handleRestart} show={this.state.isFinished}></FinishedAlert>
				<Row className="no-gutters">
					<Col xs={12} className="mb-4">
						<Tab.Container defaultActiveKey="game">
							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link eventKey="game" className="tab-bg">Bingo</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="words" className="tab-bg">Wörter</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="settings" className="tab-bg"><SettingsIcon /></Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey="game" className="tab-bg p-0 text-justify">
									<SavedPresets presets={this.state.presets}
																preset={this.state.preset}
																onSelected={this.handlePresetOnSelected}
																onCreate={this.handlePresetOnCreate}
																onSave={this.handlePresetOnSave}
																onDelete={this.handlePresetOnDelete}></SavedPresets>
									<h3 className="text-blue mt-4 mx-3">Spielen</h3>
									<Table words={words} onSelect={this.handleOnSelect}></Table>
								</Tab.Pane>
							</Tab.Content>
							<Tab.Content>
								<Tab.Pane eventKey="words" className="tab-bg p-3">
									<Words words={words} onAdd={this.handleAddInput} onWordDelete={this.handleOnWordDelete} onWordChange={this.handleChangeWord}></Words>
								</Tab.Pane>
							</Tab.Content>
							<Tab.Content>
								<Tab.Pane eventKey="settings" className="tab-bg p-1">
									<Settings onDeleteStorage={this.handleOnDeleteStorage}></Settings>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
    </Container>
		</React.Fragment>
		);
	}
}

export default Bingo;