import React, { Component } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import {TrashcanIcon} from '@primer/octicons-react'

class SettingsInputText extends Component {

	handleOnChange = (e) => {
		const w = this.props.word;
		w.word = e.target.value;
		this.props.onChange(w);
	}

	handleOnDelete = () => {
		this.props.onDelete(this.props.word.id);
	}

	handleOnKeyUp = (ev) => {
		if( ev.keyCode === 13 ) {
			this.props.onKeyUp(ev);
		}
	}

	render() {
		return (
			<InputGroup className="mt-2 mb-2">
				<Form.Control type="text" value={this.props.word.word} autoFocus={true} onChange={this.handleOnChange} onKeyUp={this.handleOnKeyUp}></Form.Control>
				<InputGroup.Append>
					<Button variant="primary"onClick={this.handleOnDelete}><TrashcanIcon size={16} /></Button>
				</InputGroup.Append>
			</InputGroup>
		);
	}
}

export default SettingsInputText;
