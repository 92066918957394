import React, { Component } from 'react';
import { Form, Row, Col, Container, Button } from 'react-bootstrap';
import SaveDialog from './Save-Dialog';
import { TrashIcon, FileIcon } from '@primer/octicons-react';

class SavedPresets extends Component {

	waitAck = false;

	constructor(props) {
		super(props);
		this.state = {deleteClass: "btn-primary"};
	}

	handleOnSelect = (ev) => {
		this.props.onSelected(ev.target.value);
	}

	handleOnSave = (id, name) => {
		this.props.onSave(id, name);
	}

	handleOnDelete = (ev) => {
		if(this.waitAck === true) {
			this.props.onDelete(this.props.preset.id);
			this.waitAck = false;
			this.setState({deleteClass: "btn-primary"});
		} else {
			this.waitAck = true;
			this.setState({deleteClass: "btn-danger"});
			setTimeout(() => { this.setState({deleteClass: "btn-primary"}); this.waitAck = false; }, 3000);
		}
	}

	handleOnCreate = () => {
		this.props.onCreate();
	}

	render() {

		let presets = [];
		if( this.props.presets !== undefined && this.props.presets !== null ) {
			presets = this.props.presets.map(p => {
				return <option value={p.id} key={p.id}>{p.name}</option>;
			});
		}

		return (
			<Container fluid>
				<Row>
					<Col xs={12} sm={12} md={7} lg={7} xl={8} className="mt-1">
						<Form>
							<Form.Group>
								<Form.Label hidden={true}>Saved Bingo!</Form.Label>
								<Form.Control as="select" value={this.props.preset?.id} onChange={this.handleOnSelect}>
									{presets}
								</Form.Control>
							</Form.Group>
						</Form>
					</Col>
					<Col xs={12} sm={12} md={5} lg={5} xl={4} className="mt-1">
						<Button onClick={this.handleOnCreate} title="Neu" className="mr-xs-1 mr-lg-1 mr-xl-1"><FileIcon size={18} /></Button>
						<SaveDialog onSave={this.handleOnSave} preset={this.props.preset} className="mx-xs-1 mx-lg-1 mx-xl-1" title="Speichern"></SaveDialog>
						<Button onClick={this.handleOnDelete} className={this.state.deleteClass + " ml-xs-1 ml-lg-1 ml-xl-1"} title="Löschen"><TrashIcon size={18}></TrashIcon></Button>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default SavedPresets;