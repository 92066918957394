import React, { Component } from 'react';
import { Modal, Form, Button, Container, Row, Col } from 'react-bootstrap';

class FinishedAlert extends Component {

	isInternal = false;

	constructor(props) {
		super(props);

		this.state = {show: this.props.show};
	}

	componentDidUpdate() {
		if(this.state.show !== this.props.show && this.isInternal === false)
			this.setState({show: this.props.show});
		this.isInternal = false;
	}

	handleClose = () => {
		this.isInternal = true;
		this.setState({show: false});
	}

	handleRestart = () => {
		this.props.onRestart();
	}

	render() {
		return (
			<Modal show={this.state.show} centered>
				<Modal.Header>
					<h2>BINGO!</h2>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Container fluid>
							<Row>
								<Col className="text-center">
									<Button variant="secondary" onClick={this.handleClose}>Fenster schließen</Button>
								</Col>
								<Col className="text-center">
									<Button variant="primary" onClick={this.handleRestart}>Spiel neu starten</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default FinishedAlert;