import WordTypeHandle from "./Word-Type-Handle";
import WordType from "./Word-Type";

export default class PresetType {
	id;
	preset;
	name;
	canStore;

	constructor(name, preset, doStore=true) {
		this.id = 0;
		this.preset = new WordTypeHandle();
		this.preset.storeSet(preset);
		this.name = name;
		this.doStore = doStore;
	}

	setName = (name) => {
		if( name !== undefined && name !== "" ) {
			this.name = name;
		}
	}

	getName = () => {
		return this.name;
	}

	createWord = () => {
		this.preset.push(new WordType("", false));
	}

	getWords = () => {
		return this.preset.getAll();
	}

	setWord = (word) => {
		this.preset.push(word);
	}

	changeWord = (word, id) => {
		this.preset.changeWord(word, id);
	}

	deleteWord = (id) => {
		this.preset.remove(id);
	}

	toggleSelected = (id) => {
		this.preset.changeSelected(id);
	}

	resetSelected = () => {
		this.preset.resetSelected();
	}

	getIndexById = (id) => {
		return this.preset.getIndex(id);
	}

	shuffle = () => {
		this.preset.shuffle();
	}
}