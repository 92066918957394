import React, { Component } from 'react';
import { Toast } from 'react-bootstrap';

class NotifyMsgUpdate extends Component {
	render() {
		return (
			<div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center align-items-center toast-fixed show-top">
					<Toast onClose={() => this.props.close() } show={() => this.props.show}>
						<Toast.Header>
							<strong className="mr-5">Neue Version verfügbar</strong>
							<small>Just now</small>
						</Toast.Header>
						<Toast.Body>
							<span className="mr-3">Zum aktualisieren klicken</span>
							<span onClick={() => this.props.update()} className="ml-2 pointer btn btn-primary btn-sm"><strong>Neu laden</strong></span>
						</Toast.Body>
					</Toast>
			</div>
		);
	}
}

export default NotifyMsgUpdate;