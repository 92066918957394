import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import SettingsInputText from './Settings-Input-Text';
import {DiffAddedIcon} from '@primer/octicons-react'

class Words extends Component {

	componentDidMount() {

	}

	handleAddInput = () => {
		this.props.onAdd();
	}

	handleKeyUp = (ev) => {
		this.props.onAdd();
	}

	handleWordChange = (word) => {
		this.props.onWordChange(word);
	}

	handleOnDelete =(id) => {
		this.props.onWordDelete(id);
	}

	additionalInput = (word) => {
		return <SettingsInputText key={word.id} word={word} onDelete={this.handleOnDelete} onChange={this.handleWordChange} onKeyUp={this.handleKeyUp}></SettingsInputText>;
	}

	render() {
		let ins = null;
		if ( this.props.words !== undefined && this.props.words !== null ) {
			ins = this.props.words.map((w) => {
				return this.additionalInput(w);
			});
		}
		return (
			<div className="text-justify">
				<h3 className="text-blue">Wortliste</h3>
				<p>
					Fügen Sie hier die Wörter für das Spiel hinzu.
				</p>
				{this.props.words === null || this.props.words.length===0 ? 
					<p>Bisher keine Wörter hinzugefügt</p>
					:
					this.props.words.length===1 ?
					<p>Bisher {this.props.words.length} Wort hinzugefügt</p>
					:
					<p>Bisher {this.props.words.length} Wörter hinzugefügt</p>
				}
				
				<Form onSubmit={e => {e.preventDefault();} }>
					<Form.Group controlId="inputWord">
						{ins}
					</Form.Group>
					<Button variant="primary" onClick={this.handleAddInput}><DiffAddedIcon size={16} /> Add Word</Button>
				</Form>
			</div>
		);
	}
}

export default Words;