import PresetType from "./Preset-Type";
import WordType from "./Word-Type";

class PresetHandler {
	presets;
	lastId;
	version=1;

	constructor() {
		this.presets = [];
		this.lastId = 0;
		const pre = JSON.parse(localStorage.getItem("presets"));
		if( pre !== undefined && pre !== null ) {
			pre.forEach(p => {
				this.push(p.name, p.preset);
			});
		}
		// default presets
		const dp = [
			new PresetType("cloud",
			[
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false),
				new WordType("cloud", false)
			], false)
		];

		dp.forEach(p => {
			this.pushPreset(p);
		})
	}

	saveToLocalStorage = () => {
		const p = this.presets.filter(p => p.doStore === true);
		localStorage.setItem("presets", JSON.stringify(p));
	}

	pushPreset = (preset) => {
		if( preset instanceof PresetType ) {
			this.lastId++;
			preset.id = this.lastId;
			this.presets.push(preset);
			this.presets.sort((a, b) => {
				return (a.getName().localeCompare(b.getName()));
			});
			this.saveToLocalStorage();
		}
	}

	push = ( name, preset, doStore=true ) => {
		const p = new PresetType(name, preset);
		this.lastId++;
		p.id = this.lastId;
		p.doStore = doStore;
		this.presets.push(p);
		this.saveToLocalStorage();
	}

	getAll = () => {
		return this.presets;
	}

	getFirst = () => {
		if( this.presets.length > 0 )
			return this.presets[0];
		// nothing in there yet create new one
		this.createNew();
		return this.getFirst();
	}

	createNew = () => {
		// nothing in there yet create new one
		const f = new PresetType("unbenannt", [], true);
		this.pushPreset(f);
		return this.lastId;
	}

	getById = (id) => {
		const idx = this.presets.findIndex(p => p.id === id);
		if( idx > -1 )
			return this.presets[idx];
		return null;
	}

	deleteById = (id) => {
		const idx = this.presets.findIndex(p => p.id === id);
		if( idx > -1 ) {
			let l = idx;
			if( idx < this.presets.length-1 ) {
				l = this.presets[idx].id;
			} else if( idx === this.presets.length-1 ) {
				if( this.presets.length > 1 )
					l = this.presets[idx-1].id;
				else if( this.presets.length === 1 )
					l = 0;
			}
			this.presets.splice(idx, 1);
			this.saveToLocalStorage();
			return l;
		}
		return 0;
	}

	changeById = (id, name, preset) => {
		const e = this.getById(id);
		if( e !== null && name !== undefined && name !== null && name.length > 0) {
			e.name = name;
			e.preset = [...preset];
			e.doStore = true;
			this.saveToLocalStorage();
		}
	}

	clear = () => {
		this.presets = [];
		this.lastId = 0;
		localStorage.removeItem("presets");
	}

	shuffle = () => {
		for(let i=0; i<this.presets.length; i++) {
			this.presets[i].shuffle();
		}	
	}
}

export default PresetHandler;