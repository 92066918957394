import React, { Component } from 'react';
import NotifyMsgUpdate from './NotifyMsgUpdate';
import NotifyMsgSuccess from './NotifyMsgSuccess';

class NotifyMsg extends Component {

	regObject;

	constructor(props) {
		super(props);

		this.state = {notifyUpdate: false};
	}

	componentDidMount() {
		this.props.notify.state.subscribe((reg) => {
			this.regObject = reg.worker;
			switch(reg.type){
				case "UPDATE":
					this.setState({notifyUpdate: true});
					break;
				case "SUCCESS":
					this.setState({notifySuccess: true});
					break;
				default:
					this.setState({notifyUpdate: false, notifySuccess: false});
			}
			if( reg.type === "UPDATE" )
				this.setState({notifyUpdate: true});
		});
	}

	handleOnClose = () => {
		this.setState({notifyUpdate: false});
	}

	handleReloadApp = () => {
		/****
		 * Reference
		 * https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
		 */
		if( this.regObject !== undefined && this.regObject !== null ) {
			this.regObject.waiting.postMessage({type: "SKIP_WAITING"});
		}
	}

	render() {
			let msg = null;
			if( this.state.notifyUpdate )
				msg = <NotifyMsgUpdate show={this.state.notifyUpdate} close={this.handleOnClose} update={this.handleReloadApp}></NotifyMsgUpdate>
			if( this.state.notifySuccess )
				msg = <NotifyMsgSuccess show={this.state.notifyUpdate} close={this.handleOnClose} delay={4000} ></NotifyMsgSuccess>
		return msg;
	}
}

export default NotifyMsg;