import React, { Component } from 'react';
import { Toast } from 'react-bootstrap';

class NotifyMsgSuccess extends Component {
	render() {
		return (
			<div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center align-items-center toast-fixed show-top">
					<Toast onClose={() => this.props.close() } show={() => this.props.show} delay={this.props.delay} autohide>
						<Toast.Header>
							<strong className="mr-5">App installed</strong>
							<small>Just now</small>
						</Toast.Header>
						<Toast.Body>
							<span className="mr-3">App is ready for offline use!</span>
						</Toast.Body>
					</Toast>
			</div>
		);
	}
}

export default NotifyMsgSuccess;