import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const Intro = (props) => {
	return (
		<React.Fragment>
			<Modal show={props.show} centered>
			<Modal.Header>
				<h1>Bingo!</h1>
			</Modal.Header>
			<Modal.Body>
				
					<p>
						Sitzen Sie auch manchmal in Meetings und hören die immer gleichen Phrasen?
					</p>
					<p>
						Wollen Sie etwas Unterhaltung und Spaß mit den Kollegen?
					</p>
					<p>
						Dann spielen Sie doch eine Runde Bingo!
					</p>
					<p>
						Geben Sie dazu einfach die Wörter in die Liste ein, die immer besonders oft verwendet werden.
						Jeder Mitspieler kann sich seine eigene Liste erstellen. Wird eines Ihrer Wörter genannt, klicken Sie einfach auf das Feld, um es zu markieren. Der Mitspieler, der als erster eine waagerechte oder senkrechte Linie voll hat, ruft laut Bingo! und hat gewonnen.
					</p>
				
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={() => props.onConfirm()}>Los geth's!</Button>
			</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
};

export default Intro;