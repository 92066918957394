import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class Maintenance extends Component {

	constructor(props) {
		super(props);

		this.state = {deleteAll: false};
	}

	handleOnClick = () => {
		this.props.onClick(this.state.deleteAll);
	}

	handleUpdate = () => {
		window.location.reload();
	}

	render() {
		return (
			<div>
				<h3>{this.props.title}</h3>
				<Button onClick={this.props.onClick} title={this.props.infoText}>{this.props.icon}</Button>
			</div>
		);
	}
}

export default Maintenance;