import React, { Component } from 'react';
import {SyncIcon, TrashIcon} from '@primer/octicons-react'
import Maintenance from './Maintenance';

class Settings extends Component {

	handleOnDeleteStorage = (deleteAll) => {
		this.props.onDeleteStorage(deleteAll);
	}

	handleUpdate = () => {
		window.location.reload();
	}

	handleOnClick = () => {
		this.props.onClick(this.state.deleteAll);
	}

	render() {
		return (
			<div className="text-justify">
				<h3 className="mt-5 text-blue">Wartung</h3>
				<Maintenance title="Auf neue Version überprüfen" icon={<SyncIcon size={16} />} infoText="Auf Updates prüfen" onClick={this.handleUpdate}></Maintenance>
				<Maintenance title="Lokalen Speicher löschen" icon={<TrashIcon size={16} />} infoText="Speicher löschen" onClick={this.handleOnDeleteStorage}></Maintenance>
			</div>
		);
	}
}

export default Settings;