import React, { Component } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { ArchiveIcon } from '@primer/octicons-react';

export default class SaveDialog extends Component{

  constructor(props) {
    super(props);

    this.state = {show: false, name: this.props.preset?.name};
  }

  handleSave = () => {
    this.props.onSave(this.props.preset.id, this.state.name);
    this.handleClose();
  };

  handleOnChange = (ev) => {
    this.setState({name: ev.target.value});
  }

  handleClose = () => this.setState({show: false});
  handleShow = () => {
		this.setState({name: this.props.preset.name, show: true});
	}

  render() {
    return (
      <React.Fragment>
        <Button variant="primary" onClick={this.handleShow}>
          <ArchiveIcon size={18} />
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Bingo Speichern</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <InputGroup className="mt-2 mb-2">
                <InputGroup.Prepend>
                Name: 
                </InputGroup.Prepend>
                <Form.Control type="text" defaultValue={this.state.name} autoFocus={true} onChange={this.handleOnChange}></Form.Control>
              </InputGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={this.handleSave}>
              Speichern
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}