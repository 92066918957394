import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

class Cell extends Component {

	handleOnSelect = () => {
		this.props.onSelect(this.props.word.id);
	}

	render() {
		let cell = <Col xs={4} onClick={this.handleOnSelect} className="row-height text-center align-middle border-left-right cell text-break d-flex align-items-center justify-content-center">
								{this.props.word.word}
							</Col>;
		if(this.props.word.isSelected === true) {
			cell = <Col xs={4} onClick={this.handleOnSelect} className="row-height text-center align-middle border-left-right marked text-break d-flex align-items-center justify-content-center">
							{this.props.word.word}
						</Col>;
		}

		return (
			cell
		);
	}
}

export default Cell;